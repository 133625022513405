<div class="seccion">
  <div class="seccion__inicio" data-aos="fade-right">
    <h2>Proyectos</h2>
    <p class="texto_proyectos">Echa un vistazo a mis últimos trabajos.</p>
    <ul class="texto-interior">
      <li class="card">
        <img src="../../assets/img/imagen_proyecto.png" alt="" />
        <h4><a (click)="abrirModal()">Portafolios Web</a></h4>
        <!-- Asegúrate de tener el evento (click) para llamar al método abrirModal() -->
        <p>Realizado con Angular, HTML y CSS</p>
      </li>
    </ul>
  </div>
</div>

<div class="modal" *ngIf="modalVisible" data-aos="fade-up">
  <div class="modal-contenido">
    <h2 class="modal-titulo">Portafolios</h2>
    <p>
      Mi portafolios, lugar donde podrás encontrar información detallada sobre
      mis habilidades, experiencia laboral, curriculum y otros aspectos
      relevantes. Además, podrás echar un vistazo a otros proyectos que iré
      compartiendo y desarrollando con el paso del tiempo.
    </p>

    <h3>Realizado en:</h3>
    <div class="seccion__tecnologias">
      <div class="column">
        <img
          src="../../assets/img/html.png"
          alt="HTML"
          title="HTML"
          class="img-tecno"
        />
      </div>
      <div class="column">
        <img
          src="../../assets/img/css.png"
          alt="CSS"
          title="CSS"
          class="img-tecno"
        />
      </div>
      <div class="column">
        <img
          src="../../assets/img/sass.png"
          alt="SASS"
          title="SASS"
          class="img-sass img-tecno"
        />
      </div>
      <div class="column">
        <img
          src="../../assets/img/angular.png"
          alt="Angular"
          title="Angular"
          class="img-tecno"
        />
      </div>
      <div class="column">
        <img
          src="../../assets/img/typescript.png"
          alt="Typescript"
          title="Typescript"
          class="imagen-cuadrada img-tecno"
        />
      </div>
    </div>
    <!-- Botón para cerrar el modal -->
    <span class="cerrar-modal" (click)="cerrarModal()">&#215;</span>
  </div>
</div>
