import { Component, ViewChild } from "@angular/core";
import emailjs, { EmailJSResponseStatus } from "emailjs-com";

@Component({
  selector: "app-contact-form",
  templateUrl: "./contact-form.component.html",
  styleUrls: ["./contact-form.component.scss"],
})
export class ContactFormComponent {
  @ViewChild("contactForm") contactForm: any;
  public name: string = "";
  public email: string = "";
  public message: string = "";
  public successMessage: string = "";
  public errorMessage: string = "";
  public showNameError: boolean = false;
  private readonly SERVICE_ID = "service_z8f04wf";
  private readonly TEMPLATE_ID = "template_m9vdmbh";
  private readonly USER_ID = "skBi2Frm26MSKuTlA";
  public isFormValid: boolean = false;
  public showEmailError: boolean = false;

  constructor() {}

  public sendEmail(): void {
    emailjs
      .sendForm(
        this.SERVICE_ID,
        this.TEMPLATE_ID,
        "#contact-form",
        this.USER_ID
      )
      .then(
        (response: EmailJSResponseStatus) => {
          this.successMessage = "Tu mensaje ha sido enviado con exito.";
          this.clearForm();
          setTimeout(() => {
            window.location.reload();
          }, 2000);
        },
        (error) => {
          console.log("FAILED...", error);
          this.errorMessage =
            "Ha ocurrido un error, por favor, intentalo de nuevo.";
        }
      );
  }

  public clearForm(): void {
    this.name = "";
    this.email = "";
    this.message = "";
    this.showEmailError = false;
  }

  public validateForm(): void {
    if (
      this.name.trim() === "" ||
      this.email.trim() === "" ||
      this.message.trim() === ""
    ) {
      this.isFormValid = false;
    } else {
      this.isFormValid = true;
    }
  }

  // Método para manejar la presentación del formulario
  onSubmit() {
    this.validateForm();
    if (this.isFormValid) {
      // Validar el nombre del usuario
      const namePattern = /^[a-zA-Z\s]*$/; // Expresión regular para validar caracteres alfabéticos y espacios
      if (!this.name.match(namePattern)) {
        this.showNameError = true; // Mostrar error si el nombre contiene caracteres no válidos
        this.errorMessage =
          "Por favor, introduce un nombre válido sin caracteres especiales.";
      } else {
        // Validar si el correo electrónico ingresado es válido
        const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
        if (!this.email.match(emailPattern)) {
          this.showEmailError = true;
          this.errorMessage =
            "Por favor, introduce una dirección de correo electrónico válida.";
        } else {
          this.sendEmail();
        }
      }
    } else {
      this.errorMessage =
        "Por favor, completa todos los campos del formulario.";
    }
  }
}
