import { Component } from '@angular/core';

interface Certification {
  name: string;
  description: string;
  image: string;
  url:string;
}

@Component({
  selector: 'app-certifications',
  templateUrl: './certifications.component.html',
  styleUrls: ['./certifications.component.scss']
})
export class CertificationsComponent {
  private readonly CERTIFICATIONS: Certification[] = [
    {
      name: 'Scrum: Marco desarrollo agil de proyectos',
      description: 'Fecha expedición: 2023',
      image: '../../assets/img/udemy_logo.jpg',
      url: 'https://udemy-certificate.s3.amazonaws.com/pdf/UC-3e03db3c-7be9-4514-998a-d77aee7c95f9.pdf'
    },
    {
      name: 'Angular de cero a experto',
      description:  'Fecha expedición: 2022',
      image: '../../assets/img/udemy_logo.jpg',
      url: 'https://udemy-certificate.s3.amazonaws.com/pdf/UC-28012cec-1980-4625-888c-dc563caa9407.pdf'
    },
    {
      name: 'Angular Core: Beginner to Advanced',
      description: 'Fecha expedición: 2022',
      image: '../../assets/img/udemy_logo.jpg',
      url: 'https://udemy-certificate.s3.amazonaws.com/pdf/UC-cc256bac-c83d-454e-bc01-bc95d0f2c808.pdf'
    },
    {
      name: 'Bootstrap 4: Maquetacion responsive y layout',
      description: 'Fecha de expedición 2022',
      image: '../../assets/img/open_webinars_logo.jpg',
      url: '../../assets/pdf/certificado_bootstrap_4_maquetación_responsive_y_layout.pdf'
    },
    {
      name: 'PHP: Ampliando conceptos',
      description: 'Fecha de expedición 2022',
      image: '../../assets/img/open_webinars_logo.jpg',
      url: '../../assets/pdf/certificado_curso_de_php_ampliando_conceptos.pdf'
    },
    {
      name: 'PHP para principiantes',
      description: 'Fecha de expedición 2022',
      image: '../../assets/img/open_webinars_logo.jpg',
      url: '../../assets/pdf/certificado_curso_de_php_para_principiantes.pdf'
    },
    {
      name: 'Master en Javascript: JS, Jquery, Angular y NodeJS',
      description: 'Fecha de expedición 2022',
      image: '../../assets/img/udemy_logo.jpg',
      url: 'https://udemy-certificate.s3.amazonaws.com/image/UC-96b1ed72-34b8-46a8-8ced-b1b26ad5071d.jpg'
    },
    {
      name: 'Curso de Javascript para principiantes',
      description: 'Fecha de expedición 2022',
      image: '../../assets/img/open_webinars_logo.jpg',
      url: '../../assets/pdf/certificado_curso_de_javascript_para_principiantes.pdf'
    },
    {
      name: 'Git Going Fast: one hour git course',
      description: 'Fecha de expedición 2022',
      image: '../../assets/img/udemy_logo.jpg',
      url: 'https://udemy-certificate.s3.amazonaws.com/pdf/UC-d9219527-eced-422b-9238-9b980f057a07.pdf'
    },
    {
      name: 'Master CSS: Responsive, Flexbox, SASS, Grid, Bootstrap',
      description: 'Fecha de expedición 2021',
      image: '../../assets/img/udemy_logo.jpg',
      url: 'https://udemy-certificate.s3.amazonaws.com/pdf/UC-64c17899-5162-44a4-b1b0-d7b5962149ba.pdf'
    }
  ];

  private readonly VISIBLE_CERTIFICATIONS_COUNT = 2;
  visibleCertifications: Certification[] = this.CERTIFICATIONS.slice(0, this.VISIBLE_CERTIFICATIONS_COUNT);
  remainingCertifications: Certification[] = this.CERTIFICATIONS.slice(this.VISIBLE_CERTIFICATIONS_COUNT);

  showMore() {
    const nextVisibleCertifications = this.remainingCertifications.splice(0, this.VISIBLE_CERTIFICATIONS_COUNT);
    this.visibleCertifications = this.visibleCertifications.concat(nextVisibleCertifications);
  }
}
