<div class="seccion" data-aos="fade-right">
  <div class="seccion__sobreMi">
    <h2 class="seccion__titulo">Sobre mí</h2>
    <h3>Saludos, bienvenido a mi portafolios ¿Quien soy?</h3>
    <h3>Soy Carlos León Vázquez</h3>
    <p>
      Comencé mi trayectoria profesional en el campo de la programación en el año 2022.
      Mi enfoque se centra principalmente en el desarrollo frontend, con
      especialización en tecnologías como Angular. Me considero un profesional
      competente que disfruta trabajar en equipo, colaborar y brindar ayuda a
      mis compañeros, aportando mis conocimientos y habilidades para seguir
      mejorando en mi desarrollo profesional. Si tuviera que definirme en tres
      palabras, serían "aprender, trabajar y mejorar".
    </p>
    <a
      href="../../assets/pdf/cleonvazquez_CV.pdf"
      download="Curriculum de Carlos"
    >
      <img
        src="../../assets/img/curriculum_icono.png"
        class="cv_icono"
      />Descargar CV</a
    >
  </div>

  <div class="row">
    <div>
      <h2 class="seccion__titulo__tecnologias">Skills</h2>
      <div class="seccion__tecnologias">

        <div class="column">
          <img src="../../assets/img/html.png" alt="HTML" title="HTML" />
          <p class="tecnologia-nombre">HTML</p>
        </div>

        <div class="column">
          <img src="../../assets/img/css.png" alt="CSS" title="CSS" />
          <p class="tecnologia-nombre">CSS</p>
        </div>

        <div class="column">
          <img src="../../assets/img/bootstrap.png" alt="Bootstrap" title="Bootstrap" class="imagen-bootstrap"/>
          <p class="tecnologia-nombre">BOOTRSTRAP</p>
        </div>

        <div class="column">
          <img src="../../assets/img/sass.png" alt="SASS" title="SASS" class="imagen-aumentar"/>
          <p class="tecnologia-nombre">SASS</p>
        </div>

        <div class="column">
          <img
            src="../../assets/img/JS.png"
            alt="Javascript"
            title="Javascript"
          />
          <p class="tecnologia-nombre">Javascript</p>
        </div>

        <div class="column">
          <img
            src="../../assets/img/angular.png"
            alt="Angular"
            title="Angular"
          />
          <p class="tecnologia-nombre">Angular</p>
        </div>

        <div class="column">
          <img
            src="../../assets/img/typescript.png"
            alt="Typescript"
            title="Typescript"
            class="imagen-cuadrada"
          />
          <p class="tecnologia-nombre">Typescript</p>
        </div>

        <div class="column">
          <img
            src="../../assets/img/csharp.png"
            alt="CSharp"
            title="Csharp"
            class="imagen-cuadrada"
          />
          <p class="tecnologia-nombre">CSharp</p>
        </div>

        <div class="column">
          <img src="../../assets/img/java.png" alt="Java" title="Java" />
          <p class="tecnologia-nombre">Java</p>
        </div>
        
        <div class="column">
          <img src="../../assets/img/php.png" alt="PHP" title="PHP"/>
          <p class="tecnologia-nombre">PHP</p>
        </div>
        
        <div class="column">
          <img
            src="../../assets/img/mongodb.png"
            alt="Mongodb"
            title="Mongodb"
          />
          <p class="tecnologia-nombre">MongoDB</p>
        </div>

        <div class="column">
          <img src="../../assets/img/mysql.png" alt="Mysql" title="Mysql" />
          <p class="tecnologia-nombre">Mysql</p>
        </div>

        
        <div class="column">
          <img src="../../assets/img/git.png" alt="Git" />
          <p class="tecnologia-nombre">Git</p>
        </div>
        
      </div>
      <!--SECCIÓN TECNOLOGIAS QUE QUIERO APRENDER-->
      <br />
      <h3 class="seccion__titulo__tecnologias">Queriendo aprender:</h3>
      <div class="seccion__tecnologias">
        <div class="column">
          <img
            src="../../assets/img/net.png"
            alt=".NET"
            title=".NET"
            class="imagen-cuadrada"
          />
          <p class="tecnologia-nombre">.Net</p>
        </div>
        <div class="column">
          <img src="../../assets/img/ionic.png" alt="Ionic" title="Ionic" />
          <p class="tecnologia-nombre">Ionic</p>
        </div>
        <div class="column">
          <img
            src="../../assets/img/rubyonrails.png"
            alt="ruby on rails"
            title="ruby on rails"
            class="imagen-cuadrada"
          />
          <p class="tecnologia-nombre">Ruby on rails</p>
        </div>
        <div class="column">
          <img
            src="../../assets/img/react.png"
            alt="react"
            title="react"
            class="imagen-react"
          />
          <p class="tecnologia-nombre">React</p>
        </div>
        <div class="column">
          <img src="../../assets/img/appian.png" alt="appian" title="appian" />
          <p class="tecnologia-nombre">Appian</p>
        </div>
      </div>
    </div>
  </div>
</div>
