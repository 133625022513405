import { Component } from '@angular/core';

@Component({
  selector: 'app-proyectos',
  templateUrl: './proyectos.component.html',
  styleUrls: ['./proyectos.component.scss']
})
export class ProyectosComponent {
  modalVisible = false;

  abrirModal() {
    this.modalVisible = true;
    document.body.style.overflow = 'hidden'; // Desactivar el scroll en el cuerpo del documento
  }

  cerrarModal() {
    this.modalVisible = false;
    document.body.style.overflow = ''; // Reactivar el scroll en el cuerpo del documento
  }
}
