<nav [ngClass]="{'nav-activo': isMenuOpen}" class="nav">
  <div class="titulo">
    <img src="../../assets/img/logo_morado_circulo.png" class="logo"> 
    <p class="nombre">Carlos León Vázquez</p>
  </div>
  <div class="enlaces transition-all" [ngClass]="{'enlaces-activos': isMenuOpen}">
    <ul class="text-center">
      <li (click)="goToHome()"><a>Inicio</a></li>
      <li (click)="goToAbout()"><a>Sobre mi</a></li>
      <li (click)="goToExperiencia()"><a>Experiencia</a></li>
      <li (click)="goToProyectos()"><a>Proyectos</a></li>
      <li (click)="goToServicios()"><a>Servicios</a></li>
      <li (click)="goToCertifications()"><a>Certificaciones</a></li>
      <li (click)="goToContact()"><a>Contacto</a></li>
    </ul>
  </div>
  <div class="menu-icon" (click)="toggleMenu()">
    <div class="menu-icon-bar"></div>
    <div class="menu-icon-bar"></div>
    <div class="menu-icon-bar"></div>
  </div>
</nav>
