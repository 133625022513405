<div class="seccion" data-aos="fade-up">
  <div class="seccion__titulo">
    <h2>Contacto</h2>
    <p>Contacta conmigo si deseas que trabajemos juntos.</p>
  </div>
  <div class="seccion__redes">
    <a href="https://www.linkedin.com/in/carleovaz/" target=”_blank”>
      <img src="../../assets/img/linkedin.png" />
    </a>
    <a href="https://github.com/carleovaz" target=”_blank”>
      <img src="../../assets/img/github.png" />
    </a>
    <a class="gmail" href="mailto:carleovaz@gmail.com">
      <img src="../../assets/img/gmail.png" title="carleovaz@gmail.com"/>
    </a>
    <a class="telefono" href="tel:695336972">
      <img src="../../assets/img/telefono.png" title="695 33 69 72"/>
    </a>
  </div>
</div>
