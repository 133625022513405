<section class="seccion_certifications" data-aos="fade-right">
  <h2 class="certifications__title">Certificaciones</h2>
  <div class="certifications">
    <div class="certifications__container">
      <div
        class="certification"
        *ngFor="let certification of visibleCertifications"
      >
        <img
          class="certification__image"
          [src]="certification.image"
          alt="Imagen de la certificación"
        />
        <p class="certification__name">{{ certification.name }}</p>
        <p class="certification__description">
          {{ certification.description }}
        </p>
        <a href="{{certification.url}}" target="_blank" class="btn-mostrar-certificacion">Mostrar certificación</a>
      </div>
    </div>
  </div>

  <button
    class="show-more"
    *ngIf="remainingCertifications.length"
    (click)="showMore()"
  >
    Mostrar más
  </button>
</section>
