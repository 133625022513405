<body>
    <div class="container_inicio">
        <img src="../../assets/img/foto.jpg" alt="foto de Carlos" class="foto">
        <ul class="texto-interior">
            <li class="saludo">¡Hola!</li>
            <li class="nombre">Soy Carlos León Vázquez</li>
            <li class="final">Desarrollador Web</li>
        </ul>
    </div>
</body>
