import { Component } from '@angular/core';

@Component({
  selector: 'app-nav',
  templateUrl: './nav.component.html',
  styleUrls: ['./nav.component.scss']
})
export class NavComponent {
  isMenuOpen = false;
  navHeight: string = 'var(--nav-height, 80px)'; // Utilizar la variable CSS --nav-height con un valor predeterminado de 80px

  constructor() { }

  ngOnInit(): void {
  }

  toggleMenu() {
    this.isMenuOpen = !this.isMenuOpen;
    const nav = document.querySelector('.nav') as HTMLElement;
    const menuIcon = document.querySelector('.menu-icon') as HTMLElement;
  
    if (nav && menuIcon) {
      if (this.isMenuOpen) {
        nav.classList.add('open'); // Agregar la clase 'open' para mostrar el nav
        menuIcon.style.transform = 'rotate(90deg)'; // Rotar el icono de hamburguesa
      } else {
        nav.classList.remove('open'); // Remover la clase 'open' para ocultar el nav
        menuIcon.style.transform = 'rotate(0)'; // Restaurar la rotación del icono de hamburguesa
      }
    }
  }

  goToHome(){
    document.getElementsByClassName('home')[0]?.scrollIntoView({behavior: "smooth"});
  }

  goToAbout(){
    document.getElementsByClassName('about')[0]?.scrollIntoView({behavior: "smooth"});
  }

  goToExperiencia(){
    document.getElementsByClassName('experiencia')[0]?.scrollIntoView({behavior: "smooth"});
  }

  goToProyectos(){
    document.getElementsByClassName('proyectos')[0]?.scrollIntoView({behavior: "smooth"});
  }

  goToServicios(){
    document.getElementsByClassName('servicios')[0]?.scrollIntoView({behavior: "smooth"});
  }

  goToCertifications(){
    document.getElementsByClassName('certifications')[0]?.scrollIntoView({behavior: "smooth"});
  }

  goToContact(){
    document.getElementsByClassName('contact')[0]?.scrollIntoView({behavior: "smooth"});
  }

}
