<section class="section_contact_form">
  <div class="contact-form">
    <form #contactForm="ngForm" id="contact-form" (ngSubmit)="onSubmit()">
      <div class="form-group">
        <label for="name">Nombre:</label>
        <input
          type="text"
          class="form-control"
          id="name"
          required
          [(ngModel)]="name"
          name="name"
          placeholder="Introduce tu nombre"
          #nameInput="ngModel"
          pattern="[a-zA-Z\sáéíóúÁÉÍÓÚñÑ]+"
          (input)="
            showNameError = nameInput.invalid && nameInput.dirty ? true : false
          "
          maxlength="50"
        />
        <div class="error-message" *ngIf="showNameError">
          <!-- Mostrar error del nombre -->
          Por favor, introduce un nombre válido.
        </div>
      </div>
      <div class="form-group">
        <label for="email">Email:</label>
        <input
          type="email"
          class="form-control"
          id="email"
          required
          [(ngModel)]="email"
          name="email"
          placeholder="Introduce tu correo"
          #emailInput="ngModel"
          pattern="[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$"
          (input)="
            showEmailError =
              emailInput?.invalid && emailInput?.dirty ? true : false
          "
          maxlength="100"
        />

        <div class="error-message" *ngIf="showEmailError">
          Por favor, Introduce un email válido
        </div>
      </div>
      <div class="form-group">
        <label for="message">Mensaje:</label>
        <textarea
          class="form-control"
          id="message"
          rows="5"
          required
          [(ngModel)]="message"
          name="message"
          placeholder="¿En qué puedo ayudarte?"
          maxlength="420"
        ></textarea>
      </div>
      <button
        type="submit"
        class="btn"
        [disabled]="!contactForm.valid || showEmailError"
      >
        Enviar
      </button>
    </form>
    <div
      *ngIf="successMessage"
      class="alert alert-success mt-3 success-message"
    >
      {{ successMessage }}
    </div>
    <div *ngIf="errorMessage" class="alert alert-danger mt-3">
      {{ errorMessage }}
    </div>
  </div>
</section>
