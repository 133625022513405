<section id=timeline data-aos="fade-up">
    <h2 class="titulo">Experiencia</h2>
    <p class="leader">Mi carrera profesional hasta la fecha.</p>
    <div class="demo-card-wrapper">
    
        <div class="demo-card demo-card--step1">
            <div class="head">
                <div class="number-box">
                    <span><img src="../../assets/img/deloitte.jpg" class="logo"></span>
                </div>
                <h2><span class="small">Junior (2022 - Act)</span> Deloitte</h2>
            </div>
            <div class="timeline-content">
                <p>Carlos fue contratado en junio de 2022 como Desarrollador Junior, formando parte de un proyecto web en el que trabajó especialmente con Angular.</p>
                <p>- Tecnologías empleadas:</p>
                <ul>
                    <li>Angular</li>
                    <li>Typescript</li>
                    <li>HTML5</li>
                    <li>SCSS</li>
                    <li>GIT</li>
                </ul>
            </div>
        </div>

        <div class="demo-card demo-card--step2">
            <div class="head">
                <div class="number-box">
                    <span><img src="../../assets/img/deloitte.jpg" class="logo"></span>
                </div>
                <h2><span class="small">Becario (2022)</span> Deloitte</h2>
            </div>
            <div class="timeline-content">
                <p>Carlos desempeñó labores como becario durante los primeros tres meses en la empresa, recibiendo formación y dedicándose a resolver pequeñas incidencias.</p>
                <p>- Tecnologías empleadas:</p>
                <ul>
                    <li>Angular</li>
                    <li>Typescript</li>
                    <li>HTML5</li>
                    <li>SCSS</li>
                    <li>GIT</li>
                </ul>
            </div>
        </div>
    </div>
</section>