<div class="seccion" data-aos="fade-up">
  <div class="header">
    <h2>Servicios</h2>
  </div>
  <div class="row1-container">
    <div class="box box-down color-border-box">
      <h3>Innovador</h3>
      <p>
        Siento una gran pasión por la investigación y el aprendizaje autónomo,
        con el objetivo de mejorar continuamente en mi trabajo. Además, disfruto
        compartiendo mis conocimientos en las áreas en las que he investigado.
      </p>
      <img src="https://assets.codepen.io/2301174/icon-supervisor.svg" alt="" />
    </div>

    <div class="box color-border-box">
      <h3>Diseñador</h3>
      <p>
        Gracias a mis estudios en desarrollo web, puedo ofrecer páginas
        responsivas con una estructura adaptada a las funcionalidades que el
        cliente desee para su sitio web.
      </p>
      <img
        src="https://assets.codepen.io/2301174/icon-team-builder.svg"
        alt=""
      />
    </div>

    <div class="box box-down color-border-box">
      <h3>Adaptivo</h3>
      <p>
        Me considero un trabajador capaz de adecuarme a diferentes situaciones y
        dedicar tiempo a adquirir nuevos conocimientos según los requerimientos
        del proyecto en cuestión.
      </p>
      <img src="https://assets.codepen.io/2301174/icon-calculator.svg" alt="" />
    </div>
  </div>
  <div class="row2-container">
    <div class="box color-border-box">
      <h3>Creativo</h3>
      <p>
        Aporto ideas útiles y creativas para la génesis y desarrollo de
        estructuras de páginas web, con el objetivo de lograr un resultado final
        lo más perfecto y pulido posible.
      </p>
      <img src="https://assets.codepen.io/2301174/icon-karma.svg" alt="" />
    </div>
  </div>
</div>
